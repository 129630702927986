import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { TextInput, SelectBox, Button } from "../../components/Form"

import {
  FRANCHISE_LIST, GOOGLE_RECAPTCHA_SITE_ID
} from "../../config/constants"
import { forgotPassword } from "../../redux/modules/auth"
import { connect } from "react-redux"
import { isApiActive } from "../../redux/modules/api"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Field, Formik } from "formik"
import { validEmail } from "../validators"
import StyledSelectField from "../../components/Form/StyledSelectField"

const FormRow = styled.div`
  margin-bottom: 14px;
`

const ForgotPasswordForm = ({ onForgotPassword, isApiActive }) => {

  const { executeRecaptcha } = useGoogleReCaptcha()

  const [googleRecaptchaToken, setGoogleRecaptchaToken] = useState(false)

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha()

    setGoogleRecaptchaToken(token)
  }, [executeRecaptcha])

  if (GOOGLE_RECAPTCHA_SITE_ID) {
    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
      handleReCaptchaVerify()
    }, [handleReCaptchaVerify])
  }

  return (
    <Formik
      initialValues={{
        email: '',
        franchise: FRANCHISE_LIST.length > 1 ? '' : FRANCHISE_LIST[0].id,
      }}
      onSubmit={(values) => {
        onForgotPassword(values.email, values.franchise, googleRecaptchaToken)
      }}
      validate={(values) => {
        const errors = {}
        if (!values?.email || !validEmail(email)) {
          errors.email = 'Invalid email address'
        }

        if (!values.franchise) {
          errors.franchise = 'Please select your region'
        }
      }
    }>
      {({ handleSubmit, errors, setFieldValue }) => (
        <div>
          <h3 style={{ fontSize: "16px", color: "#575757" }}>Reset Password</h3>
          <form
            onSubmit={handleSubmit}
          >
            {FRANCHISE_LIST.length > 1 && (
              <FormRow>
                <Field
                  as={StyledSelectField}
                  errors={errors}
                  name="franchise"
                  placeholder="Select your region"
                  options={FRANCHISE_LIST.map(eachFranchise => ({ label: eachFranchise.name, value: eachFranchise.id }))}
                  onChange={(value) => setFieldValue('franchise', value)}
                  required />
              </FormRow>
            )}
            <FormRow>
              <Field as={TextInput} name="email" errors={errors} type="email" placeholder="Email*" required/>
            </FormRow>
            <div style={{ textAlign: "right" }}>
              <Button disabled={isApiActive} size="sm" type="submit">Send</Button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  )
}

ForgotPasswordForm.propTypes = {
  onForgotPassword: PropTypes.func.isRequired, // action
  isApiActive: PropTypes.bool.isRequired, // selector
}

const mapDispatchToProps = (dispatch) => ({
  onForgotPassword: (email, franchiseId, googleRecaptchaToken) => dispatch(forgotPassword(email, franchiseId, googleRecaptchaToken)),
})

const mapStateToProps = (state) => ({
  isApiActive: isApiActive(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm)
